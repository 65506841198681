<template>
  <div>

    <section class="full-width fusion-body padrenostro" id="recipeDescr">
      <div class="container" style="flex-direction:column;">
        <div v-html="articleContent" />
      </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
  name : 'PaginaModa',
  data(){
    return {
      article : {},
      articleContent : '',
      settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "swipeToSlide": false,
        "touchThreshold": 2,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      location: window.location.href.replace('localhost:8080', 'shop.altromercato.it').replace('http:/', 'https:/'),
      related: []
    }
  },

  watch: {
    '$route.params.id': function () {
      this.initPage()
      Vue.$forceUpdate()
    }
  },

  async created(){
    await this.initPage()
  },

  methods: {
    async initPage () {
      await axios.get(`https://altromercato.it/wp-json/wp/v2/pages/17031/?_embed`)
        .then((res) => {
          this.article = res.data
          this.articleContent = this.article.content.rendered.replace(/<span[^>]*--h1_typography[^>]*>\s+<\/span>/g, '').replace(/<span[^>]*--h1_typography[^>]*>([^<]+)<\/span>/, '<h1>$1</h1>');
        })
    }
  }
}
</script>
<style>
  .container{
    width: 100%!important;
  }

  .fusion-text p, .padrenostro li {
    font-family: 'Caecilia';
  }

  .padrenostro h1,
  .padrenostro h1 b,
  .padrenostro h2,
  .padrenostro h2 b,
  .padrenostro h3,
  .padrenostro h3 b {
    font-family: 'neue-haas-grotesk-display' !important;
  }

  .padrenostro a {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .padrenostro h2,
  .padrenostro h2 b {
    font-size: 32px !important;
  }

  .padrenostro .fusion-button {
    display: inline-block !important;
    cursor: pointer !important;
    height: 46px !important;
    line-height: 46px !important;
    border-radius: 23px !important;
    padding: 0 58px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 0 !important;
  }

  @media (max-width: 768px) {
    .padrenostro .fusion-title.fusion-sep-none .title-heading-left {
      font-size: 22px !important;
    }
  }
#recipeDescr .fusion-breadcrumbs {
  margin-top: -90px;
  height: 0;
  overflow: hidden;
}
:root {
  --h1_typography-letter-spacing: 0px;
  --h1_typography-font-style: ;
  --h1_typography-font-size: 40px;
}
.title-heading-left {
  font-family: neue-haas-grotesk-display, sans-serif !important;
  font-weight: bold !important;
  padding-left: 20px !important;
}
#recipeDescr > div > div > p:nth-child(1):empty {
  display: none;
}
.container {
  line-height: 1.8;
}
#recipeDescr .fusion-button {
  height: auto !important;
  line-height: 1.2 !important;
  border-radius: 999px !important;
  padding: 12px 58px 13px !important;
}
h1 {
  font-size: 40px !important;
  font-weight: bold !important;
  font-family: neue-haas-grotesk-display, sans-serif !important;
  color: white !important;
  margin: 0 !important;
  padding: 0 !important;
}
@media only screen and (max-width: 1000px){
  #recipeDescr h2.fusion-responsive-typography-calculated {
    font-size: 32px !important;
  }
}
</style>

<style lang="scss" src="@/assets/fusion.scss"></style>
